import {
  Button,
  ButtonGroup,
  Input,
  Label,
  ModalHeader,
  ModalBody,
  FormFeedback
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import * as Yup from 'yup'
import Flatpickr from 'react-flatpickr'
import {
  useAddCallResultMutation,
  useGetCallPersonsQuery,
  useGetCallReasonsByProfileIdQuery,
  useGetCallResulstsByProfileIdQuery
} from 'src/services/calling'
import { useFormik } from 'formik'
import { useGetProfileQuery } from 'src/services/profiles'
import { useDisclosure } from 'src/utils/useDisclosure'
import { DangerAlert } from 'src/pages/Admin/ErrorPage'
import { toast } from 'react-toastify'

export const CallResultModal = ({
  loan_id,
  profile_id,
  phone = '',
  open,
  close,
  isOpen,
  ...props
}: ReturnType<typeof useDisclosure> & {
  loan_id: string
  profile_id: string
  phone?: string
  [key: string]: any
}) => {
  const { t } = useTranslation()

  const { data: profile } = useGetProfileQuery(profile_id!, {
    skip: profile_id == null
  })

  const { data: persons = [] } = useGetCallPersonsQuery()

  const { data: reasons = [] } = useGetCallReasonsByProfileIdQuery()

  const { data: results = [] } = useGetCallResulstsByProfileIdQuery()

  const [addCallResult, { error, isLoading }] = useAddCallResultMutation()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      profile_id,
      loan_id,
      contact_type: persons?.[0]?.name || '',
      result: 'Жалоба',
      reason: 'Нет причины',
      amount_promise: '',
      date_promise: '',
      comment: '',
      phone
    },
    validationSchema: Yup.object({
      contact_type: Yup.string().required('Contact type is required'),
      result: Yup.string().required('Result is required'),
      reason: Yup.string().required('Reason is required'),
      amount_promise: Yup.number()
        .typeError('Amount must be a number')
        .nullable(),
      date_promise: Yup.date().nullable().typeError('Invalid date format'),
      comment: Yup.string().nullable(),
      phone: Yup.string()
        .matches(/^[0-9]{11}$/, 'Phone number must be exactly 10 digits')
        .required('Phone number is required')
    }),
    onSubmit: async (values) => {
      const {
        profile_id,
        loan_id,
        contact_type,
        result,
        reason,
        amount_promise,
        date_promise,
        comment,
        phone
      } = values
      const resultData: any = {
        loan_id,
        profile_id,
        contact_type,
        result,
        phone
      }
      resultData.contact_type = getIdFromName(persons!, contact_type)
      resultData.result = getIdFromName(results, result)

      if (reason !== 'Нет причины') {
        resultData.reason = getIdFromName(reasons, reason)
      }

      if (amount_promise) {
        resultData.amount_promise = amount_promise
      }

      if (date_promise && !date_promise.includes('T')) {
        resultData.date_promise = `${date_promise}T00:00:00`
      }

      if (comment) {
        resultData.comment = comment
      }

      if (props.isOrder) {
        resultData.is_order = true
      } else {
        resultData.is_order = false
      }

      if (props.collectionQueueSeq) {
        resultData.collection_queue_id = props.collectionQueueSeq
      }
      try {
        await addCallResult(resultData).unwrap()
        toast.success(t('Successfully added'), {
          autoClose: 2000,
          closeOnClick: false
        })
        if (props.collectionQueueSeq) {
          navigate('/debts-queue')
        }
      } catch (error) {
        toast.error(t('Error adding'), {
          autoClose: 2000,
          closeOnClick: false
        })
      }
    }
  })

  const navigate = useNavigate()

  const autoResizeInput = (event: any) => {
    event.target.style.height = '40px'
    event.target.style.height = event.target.scrollHeight + 'px'
  }

  const header = (
    <div className='d-flex flex-row justify-content-between w-100 p-3'>
      <div className='modal-title'>
        <span>{t('Fill out call result')}</span>
      </div>
    </div>
  )

  if (!isOpen) {
    return (
      <div
        id='myModal'
        className='bg-light rounded shadow border'
        style={{
          position: 'fixed',
          right: 50,
          top: 200,
          zIndex: 1005
        }}
      >
        <div
          style={{ position: 'relative' }}
          className='d-flex flex-column h-100'
        >
          <div className='text-center' role='button' onClick={open}>
            <small className='fw-medium text-decoration-underline'>
              {t('expand')}
            </small>
          </div>
          {header}
        </div>
      </div>
    )
  }

  return (
    <>
      <div
        className='bg-light rounded shadow border'
        style={{
          position: 'fixed',
          right: 50,
          bottom: 50,
          top: 50,
          zIndex: 1005
        }}
      >
        <div
          style={{ position: 'relative' }}
          className='d-flex flex-column h-100'
        >
          <div className='text-center' role='button' onClick={close}>
            <small className='fw-medium text-decoration-underline'>
              {t('collapse')}
            </small>
          </div>
          <ModalHeader>
            {t('Recording the result of a conversation with')}
            <br />
            <Link
              className='btn-md pt-0 text-decoration-underline'
              to={`/client-view?id=${profile?.client.id}`}
            >
              {`${profile?.client?.last_name} ${profile?.client?.first_name} ${profile?.client?.middle_name}`}
            </Link>
          </ModalHeader>
          <div className='h-divider' />
          <ModalBody
            style={{
              overflow: 'auto',
              paddingTop: 0
            }}
          >
            <form
              className='vstack d-flex align-items-center justify-content-center p-2'
              onSubmit={formik.handleSubmit}
            >
              <div
                className='vstack mt-2 mb-0 align-self-center'
                style={{ width: '350px' }}
              >
                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'contact_type'}>
                    {t('Who were you talking to?')}
                  </Label>
                  <Input
                    id={'contact_type'}
                    name='contact_type'
                    value={formik.values.contact_type}
                    type={'select'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.contact_type}
                    disabled={isLoading}
                  >
                    {persons?.map((person) => (
                      <option key={person.name} value={person.name}>
                        {person.name}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>{formik.errors.contact_type}</FormFeedback>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'call_result'}>{t('result')}</Label>
                  <Input
                    id='result'
                    name='result'
                    value={formik.values.result}
                    type={'select'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.result}
                    disabled={isLoading}
                  >
                    {results.map((result) => (
                      <option key={result.id} value={result.name}>
                        {result.name}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>{formik.errors.result}</FormFeedback>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor='phone'>{t('phone')}</Label>
                  <Input
                    id='phone'
                    name='phone'
                    value={formik.values.phone}
                    type='number'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.phone}
                    disabled={isLoading}
                  />
                  <FormFeedback>{formik.errors.phone}</FormFeedback>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'reason'}>{t('reason')}</Label>
                  <Input
                    id={'reason'}
                    name='reason'
                    value={formik.values.reason}
                    type={'select'}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.reason}
                    disabled={isLoading}
                  >
                    {reasons.map((reason) => (
                      <option key={reason.id} value={reason.name}>
                        {reason.name}
                      </option>
                    ))}
                    <option value='Нет причины'>Нет причины</option>
                  </Input>
                  <FormFeedback>{formik.errors.reason}</FormFeedback>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'comment'}>{t('Comment')}</Label>
                  <Input
                    id={'comment'}
                    name='comment'
                    value={formik.values.comment}
                    style={{ height: '40px' }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onInput={autoResizeInput}
                    type={'textarea'}
                    invalid={!!formik.errors.comment}
                    disabled={isLoading}
                  />
                  <FormFeedback>{formik.errors.comment}</FormFeedback>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label htmlFor={'amount_promise'}>
                    {t('amount_promise')}
                  </Label>
                  <Input
                    id={'amount_promise'}
                    name='amount_promise'
                    value={formik.values.amount_promise}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={!!formik.errors.amount_promise}
                    disabled={isLoading}
                  />
                  <FormFeedback>{formik.errors.amount_promise}</FormFeedback>
                </div>

                <div style={{ marginBottom: '15px' }}>
                  <Label>{t('date_promise')}</Label>
                  <Flatpickr
                    className='form-control'
                    id='date_promise'
                    name='date_promise'
                    value={formik.values.date_promise}
                    onChange={(date: unknown) => {
                      formik.setFieldValue('date_promise', date)
                    }}
                    options={{
                      minDate: 'today',
                      dateFormat: 'Y-m-d'
                    }}
                    disabled={isLoading}
                  />
                  <FormFeedback>{formik.errors.date_promise}</FormFeedback>
                </div>

                {error && <DangerAlert error={error} />}
              </div>
              <ButtonGroup>
                <Button
                  style={{ marginLeft: '10px' }}
                  className='mt-3'
                  type='submit'
                  disabled={isLoading}
                >
                  {t('Send')}
                </Button>
              </ButtonGroup>
            </form>
          </ModalBody>
        </div>
      </div>
    </>
  )
}

const getIdFromName = (list: any[], name: string) =>
  list.find((item) => item.name === name)?.id
