import { api, omitBlankEntries } from './api'

export type Phone = {
  phone: string
  client_id: string
  id: number
  created_at: string
  updated_at: string | null
  dont_call: boolean
  comment: string
}

export const {
  useGetPhoneQuery,
  useGetPhonesQuery,
  useUpdatePhoneMutation,
  useAddPhoneMutation
} = api.injectEndpoints({
  endpoints: (builder) => ({
    getPhones: builder.query<Phone[], void>({
      query: () => `crm/v1/phones`,
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ type: 'Phones', id }) as const),
        { type: 'Phones' as const, id: 'LIST' }
      ]
    }),
    getPhone: builder.query<Phone, string>({
      query: (phone) => `crm/v1/phones/${phone}`,
      providesTags: (_result, _err, id) => [{ type: 'Phones', id }]
    }),
    addPhone: builder.mutation<
      void,
      Pick<Phone, 'phone' | 'comment' | 'dont_call'> & { fio: string }
    >({
      query: (body) => ({
        url: `crm/v1/phones`,
        method: 'POST',
        body: omitBlankEntries(body)
      }),
      invalidatesTags: () => ['Phones']
    }),
    updatePhone: builder.mutation<
      void,
      Partial<Omit<Phone, 'number'>> & {
        oldNumber: Phone['phone']
        newNumber: Phone['phone']
      }
    >({
      query: ({ oldNumber, newNumber, ...body }) => ({
        url: `crm/v1/phones/${oldNumber}`,
        method: 'PUT',
        body: omitBlankEntries({ ...body, number: newNumber })
      }),
      invalidatesTags: () => ['Phones']
    })
  })
})
